<template >
    <div>
      <a-alert class="alertSubmission" v-if="visible" message="Modification de la référence annuaire enregistrée!" type="success"
               closable
               :after-close="handleCloseUpdate"
      />
      <!--create a new entry for info_annuaire table-->
      <form_annu v-if="addInfoRef"></form_annu>

      <a-button type="primary" v-on:click="manageAddingInfoRef">
        Ajouter une nouvelle référence annuaire
      </a-button>
      <a-table :columns="columns" :data-source="data" v-if="renderTableStatus">
        <a slot="action" slot-scope="text" href="javascript:;" v-on:click="handleRowClick" >modifier</a>
        <a slot="delete" slot-scope="text" href="javascript:;" v-on:click="handleRowDeleteClick" >supprimer</a>
        <form_annu_mods slot="expandedRowRender" slot-scope="record" style="margin: 0" :data="annuEntry"></form_annu_mods>
      </a-table>

    </div>

</template>
<script>
import form_user from '../form_user';
import form_annu from '../form_annu';
import form_annu_mods from '../form_annu_mods'
import axios from 'axios'
const instance = axios.create({
  baseURL:'https://console.maximini.com/'
})
const connect = require("../../config_developpemenrt")
const columns = [
  { title: 'Id', dataIndex: 'id', key: 'id' },
  { title: 'Name', dataIndex: 'name', key: 'name' },
  { title: 'url', dataIndex: 'url', key: 'url' },
  { title: 'Rubrique Annuaire', dataIndex: 'annu_rubrique', key: 'annu_rubrique' },
  { title: 'Action', dataIndex: '', key: 'x', scopedSlots: { customRender: 'action' } },
  { title: 'delete', dataIndex: '', key: 'v', scopedSlots: { customRender: 'delete' } },
];


export default {
  name:'CardAnnuInfo',
  components: {
    form_user,
    form_annu,
    form_annu_mods
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    }
  },
  created() {
    console.log('initialized')
    let theTr = document.getElementsByClassName('ant-table-row-expand-icon ant-table-row-collapsed')

    //console.log(theTr)
    for(let row of theTr){
      //console.log(row.getAttribute('data-row-key'))
      row.addEventListener('click', (event)=>this.handleRowClick(event))
    }

  },
  computed: {
    renderComponentStatus(){
      return this.$store.getters.getRenderComponent
    },
    renderTableStatus(){
      return this.displayTable
    }
  },
  methods: {
    handleCloseUpdate() {
      this.visible = false;
    },
    submissionSucceded(){
      let bol = this
      this.visible= true
      console.log('submissionSucceded function is operating')
      setTimeout(function()
      {
        //handleClose is not properly working
        bol.handleCloseUpdate()
        console.log('setTimeout')

      },3000 )
    },
    forceRerender() {
      // remove the my-component component from the DOM
      //this.$store.dispatch('renderComponent')

      this.displayTable = false
      console.log(this.displayTable)
      const bac1 =this
      //setTimeout(function(){bac1.displayTable = bac1.displayTable},1000)
      /*this.$nextTick().then(function(){
        //this.$store.dispatch('renderComponent')
        if(this.displayTable === false){
          console.log('activity')
          bac1.displayTable = true
        }
        console.log(this.displayTable)
      })*/
    },
    handleRowClick: function(event){
      console.log('a click event has been intercepted detected sir')
      console.log(event)
      let rowEntry = event.target.parentElement.parentElement.getAttribute('data-row-key')
      let plus = event.target.parentElement.parentElement.getElementsByClassName('ant-table-row-expand-icon ant-table-row-collapsed')[0].click()
      //let moins = event.target.parentElement.parentElement?.getElementsByClassName('ant-table-row-expand-icon ant-table-row-expanded')[0].click()
      let entry = event.target.parentElement.parentElement.getElementsByTagName('td')[1].innerHTML
      console.log('Entry :'+entry)
      this.annuEntry = this.annuAll.filter(item => item.annu_infoData.id === +entry)
      console.log(this.annuEntry)
      console.log(event.target.parentElement.parentElement.closest('tr').innerHTML)
      let hiddenRow = event.target.parentElement.parentElement.getElementsByClassName('ant-table-expanded-row ant-table-expanded-row-level-1')

    },
    handleRowDeleteClick: function(event){
      console.log('a delete request has been intercepted detected sir')
      console.log(event)
      let rowEntry = event.target.parentElement.parentElement.getAttribute('data-row-key')
      let plus = event.target.parentElement.parentElement.getElementsByClassName('ant-table-row-expand-icon ant-table-row-collapsed')[0].click()
      //let moins = event.target.parentElement.parentElement?.getElementsByClassName('ant-table-row-expand-icon ant-table-row-expanded')[0].click()
      let entry = event.target.parentElement.parentElement.getElementsByTagName('td')[3].innerHTML
      console.log('Entry :'+entry)
      let bac = this
      instance.post(`${connect.baseUri}/delete-annu-info/`, {url:`${entry}`})
          .then((response)=> {
            console.log('a row has been updated')
            console.log(response)
           // response.status === 200 ? this.submissionSucceded() : this.submissionFailed()
            this.$store.dispatch('dataActualisation')
            bac.submissionSucceded()
            document.location.reload()
          }).catch(err=>console.log(err))
    },
    manageAddingInfoRef:function(){
      this.addInfoRef = !this.addInfoRef
      return
    }
  },
  data() {
    return {
      displayTable:true,
      columns,
      annuAll:this.data,
      annuEntry:null,
      addInfoRef:false,
      visible:false,
      formItemLayout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 },
      },
    };
  },
};
</script>
<style scoped>
.alertSubmission{
  position: fixed;
  top: 100px;
}
</style>
