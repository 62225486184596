<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->
<template>
	<div>
    <!--{{tableForm}}-->
    <registration v-if="addUser"></registration>


		<!-- Authors Table -->
		<a-row :gutter="24" type="flex">

			<!-- Authors Table Column -->
			<!--<a-col :span="24" class="mb-24">
        <a-button type="primary" v-on:click="manageAddingUser">
          Ajouter un utilisateur
        </a-button>

				<CardAuthorTable
					:data="tabledata3"
					:columns="table1Columns"
          v-if="!onlyDisplayAnnu"
				></CardAuthorTable>
				</ Authors Table Card>

			</a-col>-->
			<!-- / Authors Table Column -->

		</a-row>
		<!-- / Authors Table -->

		<!-- Projects Table -->
		<a-row :gutter="24" type="flex">

			<!-- Projects Table Column -->
			<a-col :span="24" class="mb-24">

				<!-- Projects Table Column -->
				<CardProjectTable2
					:data="annuInfo"
					:columns="table2Columns"
          v-if="!onlyDisplayAnnu"
				></CardProjectTable2>
				<!-- / Projects Table Column -->

			</a-col>
			<!-- / Projects Table Column -->

		</a-row>
		<!-- / Projects Table -->

<!--myprojectTable -->
    <a-row :gutter="24" type="flex">

      <!-- Projects Table Column -->
      <a-col :span="24" class="mb-24">

        <!-- Projects Table Column -->
        <CardAnnuInfo
          :data="reactiveAnuData"
        ></CardAnnuInfo>
        <!-- / Projects Table Column -->

      </a-col>
      <!-- / Projects Table Column -->

    </a-row>
	</div>
</template>

<script>
//privileges: inv | usr | adm
 import form_annu from "@/components/form_annu";

const axios = require('axios').default
 const bcrypt = require('bcryptjs');
 const saltRounds = 10;
 const myPlaintextPassword = 's0/\/\P4$$w0rD';
 const someOtherPlaintextPassword = 'not_bacon';

	// "Authors" table component.
import CardAuthorTable from '../components/Cards/CardAuthorTable' ;

	// "Projects" table component.
	import CardProjectTable2 from '../components/Cards/CardProjectTable2' ;
	//Sign-In form component
import form_user from '../components/form_user';
import registration from '../components/registration'
import CardAnnuInfo from '../components/Cards/CardAnnuInfo'
const connect = require('../config_developpemenrt')
console.log(connect)
// "Authors" table list of columns and their properties.
	const table1Columns = [
		{
			title: 'AUTHOR',
			dataIndex: 'author',
			scopedSlots: { customRender: 'author' },
		},
		{
			title: 'FUNCTION',
			dataIndex: 'func',
			scopedSlots: { customRender: 'func' },
		},
		{
			title: 'STATUS',
			dataIndex: 'status',
			scopedSlots: { customRender: 'status' },
		},
		{
			title: 'EMPLOYED',
			dataIndex: 'employed',
			class: 'text-muted',
		},
		{
			title: '',
			scopedSlots: { customRender: 'editBtn' },
			width: 50,
		},
	];

	// "Projects" table list of columns and their properties.
	const table2Columns = [
		{
			title: 'Url',
			dataIndex: 'company',
			scopedSlots: { customRender: 'company' },
			width: 300,
		},
		{
			title: 'Détail annuaire',
			dataIndex: 'budget',
			class: 'font-semibold text-muted',
		},
		{
			title: 'Contenu annuaire',
			dataIndex: 'status',
			class: 'font-semibold text-muted text-sm',
		},
		{
			title: 'Mots clés',
			scopedSlots: { customRender: 'completion' },
			dataIndex: 'completion',
		},
		{
			title: '',
			scopedSlots: { customRender: 'editBtn' },
			width: 50,
		},
	];

	export default({
		components: {
      CardAuthorTable,
      CardProjectTable2,
      form_user,
      registration,
      CardAnnuInfo,
    },
		data() {
			return {
			  password:'',
        bcrypt_password:'',
        userEmail:'',
        annuInfoAxios:null,
        annuInfo:[],
        onlyDisplayAnnu:true,
        tableForm:[],
				// Associating "Authors" table columns with its corresponding property.
				table1Columns: table1Columns,
				// Associating "Projects" table columns with its corresponding property.
				table2Columns: table2Columns,
        maximiniUserTable: null,
        maximiniUserTableColomn: [],
        tabledata3: [],
        renderComponent:true,
        addUser:false
			}
		},
    created() {

      this.$store.dispatch("dataActualisation")
      axios.get(`${connect.baseUri}/user-table`).then((response) => {

        this.maximiniUserTable = response.data.user
        for(let i=0; i<response.data.user.length; i++) {
          this.maximiniUserTableColomn.push({
            title: 'AUTHOR',
            dataIndex: 'author',
            scopedSlots: { customRender: 'author' },
          });
          this.tabledata3.push({
            key: `${i}`,
            author:{
              avatar: `images/${this.maximiniUserTable[i].image_name}`,
              name: `${this.maximiniUserTable[i].nom}`,
              email: `${this.maximiniUserTable[i].email}`,
            },
            func:{
              job:'opérateur développeurexpert'
            },
            status: 1,
            employed: '02/08/21',
        })
      }}).catch(error => {console.log(error)});
      //axios.get('https://console.maximini.com/annuaire-info').then((res)=>{
      axios.get(`${connect.baseUri}/annuaire-info`).then((res)=>{
      this.annuInfoAxios= res.data.user

        for(let i=0;i<this.annuInfoAxios.length;i++){


          this.$store.dispatch('storeDataAnnu',{
            key: this.annuInfoAxios[i].id,
            id: this.annuInfoAxios[i].id,
            name: this.annuInfoAxios[i].title,
            url: this.annuInfoAxios[i].url,
            annu_rubrique: this.annuInfoAxios[i].annu_rubrique,
            annu_infoData: this.annuInfoAxios[i]
          })

        }
        console.log(this.$store.getters.getAnnuData)
        //console.log(this.tableForm)
      })


    },
    computed:{
      renderComponentStatus(){
        return this.$store.getters.getRenderComponent
      },
		  reactiveAnuData(){
		    return this.$store.getters.getAnnuData
      },
      reactiveAnuDataIndex(){
		    let ordrIndex=[]
		    for(let elem of this.$store.getters.getAnnuData){
		      ordrIndex.push(elem.id)
        }
		    console.log(ordrIndex)
        return ordrIndex
      }
    },
    methods: {
		  crypt:(pass)=>{
        const password = pass
        const rounds = 10

        bcrypt.hash(password, rounds, (err, hash) => {
          if (err) {
            console.error(err)
            return
          }
        })
      },
      forceRerender () {
        // remove the my-component component from the DOM
        this.$store.dispatch('renderComponent')
        this.$nextTick(function(){
          this.$store.dispatch('renderComponent')
        }).then()

      },
      manageAddingUser:function(){
        this.addUser = !this.addUser
        return
      }
    },

	})

</script>

<style lang="scss">
</style>