<template>
  <div>
    <a-alert class="alertSubmission" v-if="visible" message="Modification de la référence annuaire effectuée!" type="success"
             closable
             :after-close="handleCloseUpdate"
    />
    <a-form
        id="components-form-demo-validate-other"
        :form="form"
        v-bind="formItemLayout"
        @submit="handleSubmitModification"
        :data-source="data"
    >
      <!--{{data}}-->
      <h2>Modification entrées annuaire: {{data[0].annu_infoData.title}}</h2>
      <div class="form-mods">
        <template>
          <a-form-item label="title">
            <div>
              <a-form-item >
                <a-input
                    allow-clear @change=""
                    v-decorator="[
						'name',
						{initialValue: data[0].annu_infoData.title,
						 rules: [{ message: 'Please enter the name' }] },
						]"

                />
              </a-form-item>
            </div>
          </a-form-item>

          <a-form-item label="url">
            <div>
              <a-form-item >
                <a-input :value="data[0].annu_infoData.url" allow-clear @change="" v-decorator="[
						'url',
						{initialValue: data[0].annu_infoData.url,
						 rules: [{ message: 'Please enter the url' }] },
						]"/>
              </a-form-item>
            </div>
          </a-form-item>


          <a-form-item label="annu_position">
            <a-input-number
                allow-clear @change=""
                v-decorator="['annu_position',
    { initialValue: +data[0].annu_infoData.annu_position }]" :min="0" :max="10" />
            <span class="ant-form-text">

      </span>
          </a-form-item>


          <a-form-item label="content">
            <div>
              <a-form-item >
                <a-textarea allow-clear @change=""
                            :rows="4"
                            v-decorator="[
						'content',
						{initialValue: data[0].annu_infoData.content,
						 rules: [{ message: 'Please enter the name' }] },
						]"/>
              </a-form-item>
            </div>
          </a-form-item>


          <a-form-item label="annu_mot_clef">
            <div>
              <a-form-item >
                <a-textarea allow-clear @change=""
                            :rows="2"
                            v-decorator="[
						'annu_mot_clef',
						{initialValue: data[0].annu_infoData.annu_mot_clef,
						 rules: [{ message: 'Please enter the key word' }] },
						]"/>
              </a-form-item>
            </div>
          </a-form-item>
          <a-form-item label="annu_detail">
            <div>
              <a-form-item >
                <a-input allow-clear @change=""
                         v-decorator="[
						'annu_detail',
						{initialValue: data[0].annu_infoData.annu_detail,
						 rules: [{ message: 'Please enter the name' }] },
						]"/>
              </a-form-item>
            </div>
          </a-form-item>

          <a-form-item label="annu_rubrique">
            <div>
              <a-form-item >
                <a-input allow-clear @change=""
                         v-decorator="[
						'annu_rubrique',
						{initialValue: data[0].annu_infoData.annu_rubrique,
						 rules: [{ message: 'Please enter the name' }] },
						]"/>
              </a-form-item>
            </div>
          </a-form-item>

          <a-form-item label="annu_sous_rubrique">
            <div>
              <a-form-item >
                <a-input allow-clear @change=""
                         v-decorator="[
						'annu_sous_rubrique',
						{initialValue: data[0].annu_infoData.annu_sous_rubrique,
						 rules: [{ message: 'Please enter the under category' }] },
						]"/>
              </a-form-item>
            </div>
          </a-form-item>


          <a-form-item label="annu_sous_rubrique2">
            <a-input allow-clear @change=""
                     v-decorator="[
						'annu_sous_rubrique2',
						{initialValue: data[0].annu_infoData.annu_sous_rubrique2,
						 rules: [{ message: 'Please enter the name' }] },
						]"/>
          </a-form-item>



        </template>
      </div>


      <a-form-item :wrapper-col="{ span: 12, offset: 0 }">
        <a-button type="primary" html-type="submit">
          Enregistrer
        </a-button>
      </a-form-item>

    </a-form>

  </div>
</template>

<script>
const axios = require('axios').default
const connect = require('../config_developpemenrt')
const instance = axios.create({
  baseURL:`${connect.baseUri}/`
})
export default {
  name: 'form_annu_mods',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    annuEntry: {
      type: Array,
      default: () => [],
    }
  },
  data: () => ({
    visible:false,
    formItemLayout: {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    },
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'validate_other' });
  },
  methods: {
    handleCloseUpdate() {
      this.visible = false;
    },
    submissionSucceded(){
      let bol = this
      this.visible= true
      console.log('submissionSucceded function is operating')
      setTimeout(function()
      {
        //handleClose is not properly working
        bol.handleCloseUpdate()
        console.log('setTimeout')

      },3000 )
    },
    handleSubmitModification(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values);
          console.log('id : '+this.data[0].annu_infoData.id)
          instance.post(`${connect.baseUri}/update-annu-info/`,{id:this.data[0].annu_infoData.id, name: values.name, url:values.url, annu_position:values.annu_position, content:values.content,
            annu_detail:values.annu_detail, annu_rubrique: values.annu_rubrique, annu_sous_rubrique: values.annu_sous_rubrique, annu_sous_rubrique2: values.annu_sous_rubrique2})
              .then((response)=> {
                console.log('here the response')
                console.log(response.status)

                if(response.status === 200){
                  this.$store.dispatch('dataActualisation')
                  this.submissionSucceded()}
              }).catch(err=>console.log(err))
        }
      });
    },
    normFile(e) {
      console.log('Upload event:', e);
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    },
  },
};
</script>
<style>
#components-form-demo-validate-other .dropbox {
  height: 180px;
  line-height: 1.5;
}
.form-mods{
  margin-left: -20vw !important;
}
.alertSubmission{
  position: fixed;
  top: 100px;
  z-index:1000
}
</style>

