<template>

  <a-form
      id="components-form-demo-validate-other"
      :form="form"
      v-bind="formItemLayout"
      @submit="handleSubmit"
      :data-source="data"
  >
    <!-- <h2>voici l'index: {{data}}</h2>-->
    <h2>Modification entrées annuaire: {{data[0].name}}</h2>

    <template v-for="item in data">
      <a-form-item label="title">
        <a-input v-decorator="[
						'name',
						{initialValue: item.name,
						 rules: [{ message: 'Please enter the name' }] },
						]"

        />
      </a-form-item>

      <a-form-item label="url">
        <a-input :value="item.url" allow-clear @change="" v-decorator="[
						'url',
						{initialValue: item.url,
						 rules: [{ message: 'Please enter the url' }] },
						]"/>
      </a-form-item>


      <a-form-item label="annu_position">
        <a-input-number v-decorator="['annu_position',
    { initialValue: +item.annu_infoData.annu_position }]" :min="0" :max="10" />
        <span class="ant-form-text">
        machines
      </span>
      </a-form-item>


      <a-form-item label="content">
        <a-input allow-clear @change=""
                 v-decorator="[
						'content',
						{initialValue: item.annu_infoData.content,
						 rules: [{ message: 'Please enter the name' }] },
						]"/>
      </a-form-item>


      <a-form-item>
        <div>
          <a-form-item label="annu_detail">
            <a-input allow-clear @change=""
                     v-decorator="[
						'annu_detail',
						{initialValue: item.annu_infoData.annu_detail,
						 rules: [{ message: 'Please enter the name' }] },
						]"/>
          </a-form-item>
        </div>
      </a-form-item>

      <a-form-item>
        <div>
          <a-form-item label="annu_rubrique">
            <a-input allow-clear @change=""
                     v-decorator="[
						'annu_rubrique',
						{initialValue: item.annu_infoData.annu_rubrique,
						 rules: [{ message: 'Please enter the name' }] },
						]"/>
          </a-form-item>
        </div>
      </a-form-item>

      <a-form-item>
        <div>
          <a-form-item label="annu_sous_rubrique">
            <a-input allow-clear @change=""
                     v-decorator="[
						'annu_sous_rubrique',
						{initialValue: item.annu_infoData.annu_sous_rubrique,
						 rules: [{ message: 'Please enter the under category' }] },
						]"/>
          </a-form-item>
        </div>
      </a-form-item>


      <a-form-item label="annu_sous_rubrique2">
        <a-input allow-clear @change=""
                 v-decorator="[
						'annu_sous_rubrique2',
						{initialValue: item.annu_infoData.annu_sous_rubrique2,
						 rules: [{ message: 'Please enter the name' }] },
						]"/>
      </a-form-item>



    </template>

    <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
      <a-button type="primary" html-type="submit">
        Enregistrer
      </a-button>
    </a-form-item>

  </a-form>
</template>

<script>
const axios = require('axios').default
const instance = axios.create({
  baseURL:'http://127.0.0.1:3000'
})
export default {
  name: 'form_user',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    annuEntry: {
      type: Array,
      default: () => [],
    }
  },
  data: () => ({
    formItemLayout: {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    },
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'validate_other' });
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values);
          console.log('id : '+this.data[0].annu_infoData.id)
          instance.post('http://127.0.0.1:3000/update-annu-info/',{id:this.data[0].annu_infoData.id, name: values.name, url:values.url, annu_position:values.annu_position, content:values.content,
            annu_detail:values.annu_detail, annu_rubrique: values.annu_rubrique, annu_sous_rubrique: values.annu_sous_rubrique, annu_sous_rubrique2: values.annu_sous_rubrique2})
              .then((response)=> {
                console.log(response.data)
                let user_privileges = response.data.user_privileges
                localStorage.setItem('user', JSON.stringify(response.data.user))
                localStorage.setItem('jwt', response.data.token)
                if (localStorage.getItem('jwt') != null) {
                  this.$emit('loggedIn')
                  console.log('loggedIn!!!!')
                  if (this.$route.params.nextUrl != null) {
                    this.$router.push(this.$route.params.nextUrl)
                    console.log('to next url')
                  }
                }
                else {
                  if (response.data.user.user_privileges === 'adm') {
                    console.log('is admin!!!')
                    this.$router.push('Tables')
                  } else {
                    this.$router.push('dashboard')
                  }
                }
              }).catch(err=>console.log(err))
        }
      });
    },
    normFile(e) {
      console.log('Upload event:', e);
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    },
  },
};
</script>
<style>
#components-form-demo-validate-other .dropbox {
  height: 180px;
  line-height: 1.5;
}
</style>

<style scoped>

</style>