<template>
  <div>
    <a-alert class="alertSubmission" v-if="visible" message="Référence annuaire enregistrée!" type="success"
             closable
             :after-close="handleClose"

   />
  <a-form
      id="components-form-demo-validate-other"
      :form="form"
      v-bind="formItemLayout"
      @submit="handleSubmit"
  >
    <!-- <h2>voici l'index: {{data}}</h2>-->
    <h2>Ajout d'une nouvelle entrée annuaire</h2>
 <div id="form-Insertion">
  <template>
     <a-form-item label="title">
      <a-textarea :rows="2" v-decorator="['title',{initialValue: 'Un nom de référence',rules: [{ message: 'Please enter the name' }] },]"
      />
    </a-form-item>

    <a-form-item label="url">
      <a-input v-decorator="[
						'url',
						{initialValue: 'www.example.com',
						 rules: [{ message: 'Please enter the url' }] },
						]"/>
    </a-form-item>

    <a-form-item label="public">
      <a-input-number
          v-decorator="[
						'public',
						{initialValue: 1}]" :min="0" :max="1"/>
    </a-form-item>


    <a-form-item label="content">
      <a-textarea allow-clear @change=""
               :rows="4"
               v-decorator="[
						'content',
						{initialValue: 'les meilleurs exemples de sites que vous puissiez imaginer',
        rules: [{ message: 'Please enter the name' }] },
        ]"/>
    </a-form-item>

    <a-form-item label="annu_detail">
      <a-textarea allow-clear @change=""
                  :rows="4"
                  v-decorator="[
						'annu_detail',
						{initialValue: 'Les détails sur le site',
        rules: [{ message: 'Please enter detail' }] },
        ]"/>
    </a-form-item>



    <a-form-item label="annu_mot_clef">
      <a-textarea allow-clear @change=""
                  :rows="2"
                  v-decorator="[
						'annu_mot_clef',
						{initialValue: 'modelisation de mot_clef',
						 rules: [{ message: 'Please enter the key word' }] },
						]"/>
    </a-form-item>



    <a-form-item label="annu_position">
      <a-input-number v-decorator="['annu_position',
    { initialValue: 0 }]" :min="0" :max="10" />
    </a-form-item>


    <a-form-item label="annu_rubrique">
      <a-textarea :rows="2" allow-clear @change=""
               v-decorator="[
						'annu_rubrique',
						{initialValue: 'développement web',
						 rules: [{ message: 'Please enter the name' }] },
						]"/>
    </a-form-item>

    <a-form-item label="annu_rubrique">
      <a-textarea :rows="2"allow-clear @change=""
               v-decorator="[
						'annu_sous_rubrique',
						{initialValue: 'Gain de temps',
						 rules: [{ message: 'Please enter the under category' }] },
						]"/>
    </a-form-item>


    <a-form-item label="annu_sous_rubrique2">
      <a-textarea :rows="2" allow-clear @change=""
               v-decorator="[
						'annu_sous_rubrique2',
						{initialValue: 'modelisation',
						 rules: [{ message: 'Please enter the name' }] },
						]"/>
    </a-form-item>

    <a-form-item label="annu_region">
      <a-input allow-clear @change=""
               v-decorator="[
						'annu_region',
						{initialValue: 'modelisation de region',
						 rules: [{ message: 'Please enter the region '}] },
						]"/>
    </a-form-item>

    <a-form-item label="annu_pays">
      <a-input allow-clear @change=""
               v-decorator="[
						'annu_pays',
						{initialValue: 'modelisation de pays',
						 rules: [{ message: 'Please enter country' }] },
						]"/>
    </a-form-item>



    <a-form-item label="annu_etoile">
      <a-input allow-clear @change=""
               v-decorator="[
						'annu_etoile',
						{initialValue: 'modelisation d etoile',
						 rules: [{ message: 'Please enter the number of stars' }] },
						]"/>
    </a-form-item>

    <a-form-item label="annu_responsable">
      <a-textarea :rows="2" allow-clear @change=""
               v-decorator="[
						'annu_responsable',
						{initialValue: 'Mr frixon',
						 rules: [{ message: 'Please enter the name' }] },
						]"/>
    </a-form-item>

    <a-form-item label="annu_webmaster">
      <a-input allow-clear @change=""
               v-decorator="[
						'annu_webmaster',
						{initialValue: 'Mr frixon Mr urcel',
						 rules: [{ message: 'Please enter the webmaster name' }] },
						]"/>
    </a-form-item>

    <a-form-item label="annu_entreprise">
      <a-textarea :rows="2" allow-clear @change=""
               v-decorator="[
						'annu_entreprise',
						{initialValue: 'dev expert',
						 rules: [{ message: 'Please enter the company name' }] },
						]"/>
    </a-form-item>

    <a-form-item label="annu_adresse">
      <a-textarea :rows="2" allow-clear @change=""
               v-decorator="[
						'annu_adresse',
						{initialValue: 'modelisation adresse',
						 rules: [{ message: 'Please enter the adress' }] },
						]"/>
    </a-form-item>

    <a-form-item label="annu_adresse2">
      <a-textarea :rows="2" allow-clear @change=""
               v-decorator="[
						'annu_adresse2',
						{initialValue: 'modelisation adresse2',
						 rules: [{ message: 'Please enter the adress2' }] },
						]"/>
    </a-form-item>

    <a-form-item label="annu_code_postal">
      <a-input allow-clear @change=""
               v-decorator="[
						'annu_code_postal',
						{initialValue: 'code_postal',
						 rules: [{ message: 'Please enter the ZIP code' }] },
						]"/>
    </a-form-item>


    <a-form-item label="annu_ville">
      <a-input allow-clear @change=""
               v-decorator="[
						'annu_ville',
						{initialValue: 'annu_ville',
						 rules: [{ message: 'Please enter the town' }] },
						]"/>
    </a-form-item>

    <a-form-item label="annu_telephone">
      <a-input allow-clear @change=""
               v-decorator="[
						'annu_telephone',
						{initialValue: 'telephone',
						 rules: [{ message: 'Please enter the phone number' }] },
						]"/>
    </a-form-item>

    <a-form-item label="annu_mobile">
      <a-input allow-clear @change=""
               v-decorator="[
						'annu_mobile',
						{initialValue: 'modelisation mobile',
						 rules: [{ message: 'Please enter the cell phone' }] },
						]"/>
    </a-form-item>

    <a-form-item label="annu_email">
      <a-input allow-clear @change=""
               v-decorator="[
						'annu_email',
						{initialValue: 'email@example.com',
						 rules: [{ message: 'Please enter the email adress' }] },
						]"/>
    </a-form-item>

    <a-form-item label="annu_note">
      <a-textarea :rows="4" allow-clear @change=""
               v-decorator="[
						'annu_note',
						{initialValue: 'remember me the note',
						 rules: [{ message: 'Please enter the note' }] },
						]"/>
    </a-form-item>


  </template>
</div>



    <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
      <a-button type="primary" html-type="submit">
        Enregistrer
      </a-button>
    </a-form-item>

  </a-form>
  </div>
</template>

<script>
const axios = require('axios').default
const connect = require('../config_developpemenrt')
const instance = axios.create({
  baseURL:`${connect.baseUri}/`
})
const basepath= require('./developpement_env').default
console.log('base:'+ basepath)
export default {
  name: 'form_annu',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    annuEntry: {
      type: Array,
      default: () => [],
    }
  },
  data: () => ({
    formItemLayout: {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    },
    visible: false,
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'validate_other' });
  },
  methods: {
    handleClose() {
      this.visible = false;
    },
    forceRerender () {
      // remove the my-component component from the DOM
      this.$store.dispatch('renderComponent')
      this.$nextTick(function(){
        this.$store.dispatch('renderComponent')
      }).then()

    },
    submissionSucceded(){
      let bol = this
      this.visible = true
      console.log('submissinSucceded function is operating')
      setTimeout(bol.handleClose,3000 )
    },
    submissionFailed(){
      console('An error occured Sir')
    },
    handleSubmit(e) {
      let bac= this
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if(err){console.log(err)}
        if (!err) {
          console.log('Received values of form_annu: ', values);
          instance.post(`${connect.baseUri}/insert-annu-info/`, values)
              .then((response)=> {
                console.log(response.status)
                response.status === 200 ? bac.submissionSucceded() : bac.submissionFailed()
                this.$store.dispatch('dataActualisation')
                document.location.reload()
                //Vue.$forceUpdate()
                //this.$parent.forceRerender()
              }).catch(err=>console.log(err))
        }
      });
    },
    normFile(e) {
      console.log('Upload event:', e);
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    },
  },
};
</script>
<style>
#components-form-demo-validate-other .dropbox {
  height: 180px;
  line-height: 1.5;

}
.alertSubmission{
  position: fixed;
  top: 100px;
}
#form-Insertion {
  margin-left: -18vw !important;
}
</style>

